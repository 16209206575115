'use strict';
// 全局轮播弹窗div

var gbSwiper = null;
if ($('.gb-swiper').size() > 0) {
    gbSwiper = new Swiper('.gb-swiper', {
        navigation: {
            nextEl: '.gb-swiper-next',
            prevEl: '.gb-swiper-prev'
        },
        observer: true,
        observeParents: true,
        loop: true
    });
}

$(function () {

    var locationInput = $('.js-location-input');
    var searchApart = $('.search_apart');
    // locationInput.blur(function () {
    //     setTimeout(() => {
    //         $(this).siblings('.js-area-list').hide()
    //     }, 200);
    // })
    $(document).on('click', function (e) {
        var _con = $('.location'); // 设置目标区域
        if (!_con.is(event.target) && _con.has(e.target).length === 0) {
            // Mark 1
            $('.js-area-list').slideUp();
        }
    });
    locationInput.focus(function () {
        $(this).siblings('.js-area-list').show();
    });

    var hasContains = function hasContains(item, value) {
        return item.names.toUpperCase().indexOf(value.toUpperCase()) >= 0;
    };

    var debounce = function debounce(idle, action) {
        var last;
        return function () {
            var ctx = this,
                args = arguments;
            clearTimeout(last);
            last = setTimeout(function () {
                action.apply(ctx, args);
            }, idle);
        };
    };
    var result = [];
    // var cloneList = $('.search_apart').clone()
    // let result = $.ajax({ url: ROOT + "/Index/cation_area", async: false });
    // let result = $.ajax({ url: "http://fidu.spvi.cn/Index/cation_area", async: false });
    // result = result.responseJSON.mess
    var list = $('.js-area-list ul');
    var htmlstr = '';
    for (var i = 0; i < result.length; i++) {
        var element = result[i];
        htmlstr += '<li data-value="' + element.id + '">' + element.names + '</li>';
    }
    list.append($(htmlstr));

    locationInput.on('input', debounce(100, function (e) {
        var val = e.target.value;
        var currentList = $(this).siblings('.js-area-list');
        var str = '<li data-value="">All Citys</li>';
        for (var _i = 0; _i < result.length; _i++) {
            var _element = result[_i];
            if (hasContains(_element, val)) {
                str += '<li data-value="' + _element.id + '">' + _element.names + '</li>';
            }
        }
        var oUl = currentList.find('ul');
        oUl.empty();
        oUl.append($(str));
    }));

    $('html,body').on('click', '.js-area-list li', function (e) {
        var text = $(this).text();
        var value = $(this).data('value');
        var currentLocation = $(this).closest('.location');
        currentLocation.find('.js-location-input').val(text);
        currentLocation.find('.search_community').val(value);
    });

    $('.header .nav .icon-sear').click(function () {
        $('.search-modal').show();
    });
    $('.search-modal .header-sear-close').click(function () {
        $('.search-modal').hide();
    });
    $('.search-modal .con .head dd').hover(function () {
        var index = $(this).index();
        if (index == 1) return false;
        $(this).addClass('active').siblings().removeClass('active');
        $('.search-modal .con .body .item').hide().eq(index).show();
    });

    // 显示vr
    $('.show-vrbox').click(function () {
        $('.pc-slide').toggleClass('active');
    });

    $('.error-modal .close').click(function () {
        $('.error-modal').fadeOut();
    });

    //- 下拉菜单
    $('.js-select.select-list li').on('click', function () {
        var value = $(this).data('value');
        $(this).parents('.select-list').siblings('.hiddenValue').val(value);
        $(this).addClass('active').siblings().removeClass('active');
        var text = $(this).text();
        $(this).parents().siblings('span').text(text);
    });

    $('.index-home .con .head dd').hover(function () {
        var index = $(this).index();
        if (index == 1) return false;
        $(this).addClass('active').siblings().removeClass('active');
        $('.index-home .con .body .item').hide().eq(index).show();
    });
    $('.sp-select').each(function (index, item) {
        var selectedNode = $(this).find('.select-list .is-active');
        var value = selectedNode.attr('value');
        var text = selectedNode.html();
        $(this).find('.select-val').val(value);
        $(this).find('.select-title').html(text);
    });

    $('.login-modal .head ul li').click(function () {
        var index = $(this).index();
        $(this).addClass('active').siblings().removeClass('active');
        $(this).closest('.login-modal').find('.tab li').removeClass('active').eq(index).addClass('active');
    });

    $('.modal-wrapper .close').click(function () {
        $('.modal-mask').hide();
        $('.modal-wrapper').hide();
    });

    $('.modal-mask').click(function () {
        $('.modal-mask').hide();
        $('.modal-wrapper').hide();
    });
    $('[data-modal-traget]').on('click', function () {
        $('.modal-mask').show();
        var id = $(this).data('modal-traget');
        $('#' + id).fadeIn();
        return false;
    });

    $('.header .meun-icon').click(function () {
        $('.header').addClass('is-open');
    });
    $('.header .meun-close').click(function (e) {
        $('.header').removeClass('is-open');
    });

    $('[data-modal-close]').on('click', function () {
        $('.modal-mask').fadeOut();
        var el = $(this).data('modal-close');
        $('#' + el).fadeOut();
    });

    $('.preferences-modal .btns button').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
    });

    $('body').on('click', '.select-list .item', function () {
        $(this).addClass('is-active').siblings().removeClass('is-active');
        var value = $(this).attr('value');
        var text = $(this).html();
        var parentNode = $(this).closest('.sp-select');
        parentNode.removeClass('is-active');
        parentNode.find('.select-val').val(value);
        parentNode.find('.select-title').html(text);
    });

    $(document).click(function (e) {
        var _con = $('.sp-select'); // 设置目标区域
        if (!_con.is(e.target) && _con.has(e.target).length === 0) {
            $('.sp-select').removeClass('is-active');
        }
    });

    $('.sp-select h3').on('click', function () {
        $('.sp-select').removeClass('is-active');
        var $this = $(this).parent();
        if ($this.hasClass('is-active')) {
            $this.removeClass('is-active');
        } else {
            $this.addClass('is-active');
        }
    });

    setTimeout(function () {
        $('.aside-nav.active .logo img').addClass('fadeIn animated');
    }, 500);

    new WOW({
        boxClass: 'wow', // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: false, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        callback: function callback(box) {},
        scrollContainer: null // optional scroll container selector, otherwise use window
    }).init();

    $('.aside-nav .nav-btn').click(function () {
        $('.aside-nav').toggleClass('active');
        $('.layout').toggleClass('active');
    });

    $(document).on('click', '.aside-nav.active .nav li .icon', function () {
        $('.aside-nav').removeClass('active');
        $('.layout').removeClass('active');
        return false;
    });

    scroll();
    $(window).scroll(scroll);
    function scroll() {
        var scrollTop = $(window).scrollTop();
        if (banner > 0) {
            var timer = null;
            timer = setInterval(function () {
                swiper.update();
            }, 10);
            setTimeout(function () {
                clearInterval(timer);
            }, 800);
        }
        if (scrollTop > 200) {
            $('.aside-nav').addClass('is-show');
            if ($('.layout').hasClass('active')) return false;
            $('.aside-nav').addClass('active');
            $('.layout').addClass('active');
            $('.header').addClass('is-active');
            $('.header').removeClass('is-open');
        } else {
            if ($('.layout').hasClass('active')) {
                $('.layout').removeClass('active');
                $('.aside-nav').removeClass('is-show');
                $('.header').removeClass('is-active');
            }
        }
    }

    var swiper = null;
    var banner = $('.index-banner').size();
    if (banner > 0) {
        swiper = new Swiper('.index-banner', {
            effect: 'fade',
            autoplay: {
                delay: 8000,
                disableOnInteraction: false
            },
            on: {
                slideChangeTransitionEnd: function slideChangeTransitionEnd() {
                    $('.banner-con .slogen li').hide().eq(this.activeIndex).show();
                }
            }
        });
    }
});

// let message = {
//     init(){
//         if($('.message-wrap').size()==0){
//             $('body').append(`<div class="message-wrap"></div>`)
//             $('.message-wrap').addClass('fadeIn')
//         }
//     },

//     error(msg,time = 2000){


//     },
//     success(msg,time = 2000){
//         this.init()
//         $('.message-wrap').append(`<div class ="success">${msg}</div>`)
//         this.destroy(time)
//     }
// }